import React from 'react';
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { MenuItem, Menu, useTheme, Grid, useMediaQuery, Typography, Link, List, ListItem, ListItemText, IconButton, Box, CircularProgress } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import { Paper, Container } from '@material-ui/core';
import logoLight from '../assets/img/sendity-light.svg'
import logoDark from '../assets/img/sendity-dark.svg'
import { useTranslation } from 'react-i18next';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YoutubeIcon from '@material-ui/icons/YouTube';
import { Link as RouterLink } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { MenuDrawer } from './menuDrawer';
import { useApi, useAuth, useContext } from '../context';
import { Config } from '../config';
import utils from '../services/utils.service';
import { UserProfile } from '../services/types/userProfile.type';


const config = new Config();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    logoContainer: {
      flexGrow: 1,
    },
    logo: {
      height: 40,
      maxWidth: '100%'
    },
    container: {
      flexGrow: 1,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(6)
    },
    footerContainer: {
      backgroundColor: theme.palette.secondary.dark,
    },
    footerWrapper: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10)
    },
    subFooterContainer: {
      backgroundColor: theme.palette.secondary.main,
    },
    subFooterWrapper: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4)
    },
    footerText: {
      color: theme.palette.primary.contrastText
    },
    toolBarContainer: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 8
    },
    toolbar: {
      minHeight: 100,
    },
    socialIconsBox: {
      backgroundColor: '#C4C4C41A',
      borderRadius: 8,
      display: 'flex',
      width: 250,
      justifyContent: 'space-around',
      padding: 3
    },
    footerLogo: {
      maxWidth: 200,
    },
    signupButton: {
      backgroundColor: theme.palette.primary.dark
    }
  }),
);

const StyledButton = withStyles({
  label: {
    textTransform: 'none',
    fontSize: 16
  },
})(Button);

const StyledRoundButton = withStyles({
  root: {
    borderRadius: 20,
    paddingLeft: 25,
    paddingRight: 25
  },
  label: {
    fontSize: 16,
    textTransform: 'none',
  },
})(Button);

const LanguageButton = () => {
  const { t, i18n } = useTranslation('layout');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openLanguageMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeLanguageMenu = () => {
    setAnchorEl(null);
  };

  const setLanguage = (language: 'en' | 'es') => {
    i18n.changeLanguage(language);
    closeLanguageMenu();
  }

  return (
    <>
      <StyledButton aria-controls='language-menu' aria-haspopup='true' onClick={openLanguageMenu}>
        {i18n.language === 'en' &&
          'EN'
        }
        {i18n.language === 'es' &&
          'ES'
        }
        <ExpandMoreIcon fontSize='small' />
      </StyledButton>
      <Menu
        id='language-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeLanguageMenu}
      >
        <MenuItem onClick={() => setLanguage('en')}>{t('ENGLISH')}</MenuItem>
        <MenuItem onClick={() => setLanguage('es')}>{t('SPANISH')}</MenuItem>
      </Menu>
    </>
  );
};

const Loading = () => (
  <Grid container alignContent='center' justify='center'>
    <Grid item>
      <CircularProgress />
    </Grid>
  </Grid>
)

export const Layout = ({ children }: { children: any }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation('layout');
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const context = useContext();
  const [loadingProfile, setLoadingProfile] = React.useState(true);
  const [profile, setProfile] = React.useState<UserProfile | null>(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const api = useApi();

  const { locale } = (() => {
    if (location.search) {
      const query = new URLSearchParams(location.search);
      return {
        locale: query.get('locale') === 'en' ? 'en' : 'es'
      };
    } else {
      return {
        locale: 'es'
      }
    }
  })();

  React.useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);

  React.useEffect(() => {
    if (context.data.auth.ready && context.data.auth.isSignedIn) {
      utils.runAsync(async () => {
        setProfile(await api.getMyProfile());
      }, () => {
        setLoadingProfile(false);
      });
    } else if (context.data.auth.ready) {
      setLoadingProfile(false);
    }
  }, [context.data.auth])

  if (loadingProfile) {
    return <Loading />
  } else {
    const validProfile = profile && profile.kyc.valid && profile.profile.complete;
    if (!validProfile) {
      window.location.href = `${config.MAIN_REDIRECT_URL}/session-expired`;
      return <Loading />
    } else {
      try {
        window.connectif.managed.sendEvents([{ type: 'page-visit' }], { entityInfo: { primaryKey: profile.profile.data.email ?? 'anonymous' } });
      } catch (e) {
        console.error(e)
      }
    }
  }
  return (
    <div className={classes.root}>
      <MetaTags>
        <title>{t('TITLE')}</title>
        <meta name='description' content={t('DESCRIPTION')} />
      </MetaTags>
      <MenuDrawer open={isSm ? drawerOpen : false} onClose={() => { setDrawerOpen(false) }} />
      <AppBar position='static' color='transparent' variant='outlined'>
        <Container className={classes.toolBarContainer}>
          <Toolbar className={classes.toolbar}>
            <Grid container alignItems='center' spacing={2}>
              <Grid item xs={10} md={4}>
                <Grid container justify={'flex-start'}>
                  <Grid item>
                    <Link href='https://www.sendity.com'>
                      <img className={classes.logo} src={logoLight} />
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              {!isSm &&
                <Grid item md={8}>
                  <Grid container justify={'flex-end'} spacing={2}>
                    <Grid item>
                      <StyledButton component={RouterLink} to='/transfer' color='inherit'>{t('NEW TRANSFER')}</StyledButton>
                    </Grid>
                    <Grid item>
                      <StyledButton href={i18n.language === 'es' ? 'https://www.sendity.com/contacto' : 'https://www.sendity.com/en/contact'} color='inherit'>{t('HELP')}</StyledButton>
                    </Grid>
                    <Grid item>
                      <StyledRoundButton component={Link} href={process.env.MAIN_REDIRECT_URL} color='primary' variant='outlined'>{t('MY ACCOUNT')}</StyledRoundButton>
                    </Grid>
                    <Grid item>
                      <LanguageButton />
                    </Grid>
                  </Grid>
                </Grid>
              }
              {isSm &&
                <Grid item xs={2} md={8}>
                  <Grid container justify={'flex-end'} spacing={2}>
                    <Grid item>
                      <IconButton color='inherit' onClick={() => setDrawerOpen(true)}><MenuIcon /></IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      <div className={classes.container}>
        {children}
      </div>
      <Paper className={classes.footerContainer} variant='outlined' square={true}>
        <Container className={classes.footerWrapper}>
          <Grid container spacing={2}>
            <Grid item direction='column' md={3} sm={12} container justify='flex-start' alignContent={isSm ? 'center' : 'flex-start'} alignItems={isSm ? 'center' : 'flex-start'}>
              <Grid item>
                <Typography variant='subtitle1' className={classes.footerText}>{t('MORE_INFO')}</Typography>
              </Grid>
              <Grid item>
                <List dense>
                  <ListItem disableGutters component='a' href={i18n.language === 'es' ? 'https://www.sendity.com/blog' : 'https://www.sendity.com/en/blog'} rel='noopener' target='_blank' className={classes.footerText}>
                    <ListItemText primaryTypographyProps={{ align: isSm ? 'center' : 'inherit' }} primary={t('BLOG')} />
                  </ListItem>
                  <ListItem disableGutters component='a' href={i18n.language === 'es' ? 'https://www.sendity.com/sobre-nosotros' : 'https://www.sendity.com/en/about-us'} rel='noopener' target='_blank' className={classes.footerText}>
                    <ListItemText primaryTypographyProps={{ align: isSm ? 'center' : 'inherit' }} primary={t('ABOUT_US')} />
                  </ListItem>
                  <ListItem disableGutters component='a' href={i18n.language === 'es' ? 'https://www.sendity.com/contacto' : 'https://www.sendity.com/en/contact'} rel='noopener' target='_blank' className={classes.footerText}>
                    <ListItemText primaryTypographyProps={{ align: isSm ? 'center' : 'inherit' }} primary={t('FAQ')} />
                  </ListItem>
                  <ListItem disableGutters component='a' href={i18n.language === 'es' ? 'https://www.sendity.com/contacto' : 'https://www.sendity.com/en/contact'} rel='noopener' target='_blank' className={classes.footerText}>
                    <ListItemText primaryTypographyProps={{ align: isSm ? 'center' : 'inherit' }} primary={t('CONTACT')} />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <Grid item direction='column' md={6} sm={12} container justify='flex-start' alignContent={isSm ? 'center' : 'flex-start'} alignItems={isSm ? 'center' : 'flex-start'}>
              <Grid item>
                <Typography variant='subtitle1' className={classes.footerText}>{t('LEGAL')}</Typography>
              </Grid>
              <Grid item>
                <List dense>
                  <ListItem disableGutters component='a' href={i18n.language === 'es' ? 'https://www.sendity.com/terminos-condiciones' : 'http://www.sendity.com/en/terms-conditions'} rel='noopener' target='_blank' className={classes.footerText}>
                    <ListItemText primaryTypographyProps={{ align: isSm ? 'center' : 'inherit' }} primary={t('TERMS_CONDITIONS')} />
                  </ListItem>
                  <ListItem disableGutters component='a' href={i18n.language === 'es' ? 'https://www.sendity.com/politica-privacidad' : 'https://sendity.com/en/privacy-policy'} rel='noopener' target='_blank' className={classes.footerText}>
                    <ListItemText primaryTypographyProps={{ align: isSm ? 'center' : 'inherit' }} primary={t('PRIVACY_POLICY')} />
                  </ListItem>
                  <ListItem disableGutters component='a' href={i18n.language === 'es' ? 'https://www.sendity.com/politica-cookies' : 'https://sendity.com/en/cookies-policy'} rel='noopener' target='_blank' className={classes.footerText}>
                    <ListItemText primaryTypographyProps={{ align: isSm ? 'center' : 'inherit' }} primary={t('COOKIES_LINK')} />
                  </ListItem>
                  <ListItem disableGutters component='a' href={i18n.language === 'es' ? 'https://sendity.com/bases-legales-promocion' : 'https://sendity.com/bases-legales-promocion'} rel='noopener' target='_blank' className={classes.footerText}>
                    <ListItemText primaryTypographyProps={{ align: isSm ? 'center' : 'inherit' }} primary={t('BASES_LEGALES_PROMOCIONES')} />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            {/* <Grid item direction='column' md={3} sm={12} container justify='flex-start' alignContent={isSm ? 'center' : 'flex-start'} alignItems={isSm ? 'center' : 'flex-start'}>
              <Grid item>
                <Typography variant='subtitle1' className={classes.footerText}>{t('HELP')}</Typography>
              </Grid>
              <Grid item>
                <List dense>
                  <ListItem disableGutters component='a' href='https://www.sendity.com/help' rel='noopener' target='_blank' className={classes.footerText}>
                    <ListItemText primaryTypographyProps={{ align: isSm ? 'center' : 'inherit' }} primary={t('HELP_CENTER')} />
                  </ListItem>
                  <ListItem disableGutters component='a' href='https://www.sendity.com/whatsapp' rel='noopener' target='_blank' className={classes.footerText}>
                    <ListItemText primaryTypographyProps={{ align: isSm ? 'center' : 'inherit' }} primary={t('WHATSAPP')} />
                  </ListItem>
                  <ListItem disableGutters component='a' href='mailto:hola@sendity.com' rel='noopener' target='_blank' className={classes.footerText}>
                    <ListItemText primaryTypographyProps={{ align: isSm ? 'center' : 'inherit' }} primary={'hola@sendity.com'} />
                  </ListItem>
                </List>
              </Grid>
            </Grid> */}
            <Grid item container md={3} sm={12}>
              <Grid container justify='center' alignContent='center' alignItems='center' spacing={4}>
                <Grid item xs={12}>
                  <Grid container justify='center'>
                    <Grid item>
                      <img className={classes.footerLogo} src={logoDark} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justify='center'>
                    <Grid item>
                      <Box className={classes.socialIconsBox}>
                        <IconButton component='a' rel='noopener' target='_blank' href='https://www.facebook.com/sendityapp' aria-label='facebook'>
                          <FacebookIcon className={classes.footerText} />
                        </IconButton>
                        <IconButton component='a' rel='noopener' target='_blank' href='https://www.youtube.com/channel/UCHmC0BHD5VJwlVV-S3Y0WLQ' aria-label='youtube'>
                          <YoutubeIcon className={classes.footerText} />
                        </IconButton>
                        <IconButton component='a' rel='noopener' target='_blank' href='https://www.instagram.com/appsendity/' aria-label='instagram'>
                          <InstagramIcon className={classes.footerText} />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Paper className={classes.subFooterContainer} variant='outlined' square={true}>
        <Container className={classes.subFooterWrapper}>
          <Typography className={classes.footerText} style={{ fontSize: 14 }} align='center'>
            {t('SUBFOOTER')}
          </Typography>
        </Container>

      </Paper>
    </div>
  )
};

