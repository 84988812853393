import { CircularProgress, Grid, TextField, Typography, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import { AlertDialog } from '../../components/alertDialog';
import { useApi, useContext } from '../../context';
import { AccountType } from '../../services/types/acocuntType.type';
import { Agent } from '../../services/types/agent.type';
import { Area, CubaZones, Municipality, Zone } from '../../services/types/cubaZones.type';
import { Quotation } from '../../services/types/quotation.type';
import { TransactionTypeCode } from '../../services/types/transactionType.type';
import utils from '../../services/utils.service';
import { TransferWizard, TransferWizardStep } from '../wizard';
import { QuotationInfo } from './quotationInfo';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

type Errors = {
  accountFields: { [key: string]: string | undefined };
}

export const CubaHomeDelivery = () => {
  const context = useContext();
  const api = useApi();
  const history = useHistory();

  const productSelectionParams = context.data.productSelectionParams

  if (
    !productSelectionParams ||
    ![
      TransactionTypeCode.HOME_DELIVERY,
    ].includes(productSelectionParams.transactionType.code) ||
    productSelectionParams.country.iso2 !== 'CU'
  ) {
    return (
      <Redirect to='/' />
    )
  }

  const { t } = useTranslation(['transferOptions', 'accountType']);

  const [quotation, setQuotation] = React.useState<Quotation>();
  const [loading, setLoading] = React.useState(true);
  const [selectedAgent, setAgent] = React.useState<Agent | null>(null);
  const [selectedAccountType, setAccountType] = React.useState<AccountType | null>(null);

  const [hasLoadingError, setHasLoadinError] = React.useState(false);

  const [zoneData, setZoneData] = React.useState<CubaZones | null>(null);

  const [selectedZone, setZone] = React.useState<Zone | null>(null);
  const [selectedArea, setArea] = React.useState<Area | null>(null);
  const [selectedMunicipality, setMunicipality] = React.useState<Municipality | null>(null);

  const [errors, setErrors] = React.useState<Errors>({} as Errors);

  const validate = () => {
    const accountErrors: { [key: string]: string | undefined } = {};
    accountErrors['zone'] = selectedZone ? undefined : t('REQUIRED');
    accountErrors['area'] = selectedArea ? undefined : t('REQUIRED');
    accountErrors['municipality'] = selectedMunicipality ? undefined : t('REQUIRED');
    const errs = {
      accountFields: accountErrors,
    };
    setErrors(errs);
    return !(
      Object.values(accountErrors).find(i => i !== undefined) !== undefined
    );
  }

  const selectProduct = () => {
    if (!validate()) {
      return;
    }
    context.setData({
      quotation,
      productAttributes: {
        account: {
          fields: [
            {
              name: 'zone',
              value: selectedZone!!.name
            },
            {
              name: 'area',
              value: selectedArea!!.name
            },
            {
              name: 'municipality',
              value: selectedMunicipality!!.name
            },
          ],
          type: selectedAccountType!!
        },
        agent: selectedAgent!!
      }
    });
    history.push('/transfer/beneficiary')
  }


  const loadData = () => {
    setLoading(true);
    utils.runAsync(async () => {
      const agents = await api.getAgents(
        productSelectionParams.country.id,
        productSelectionParams.currency.id,
        productSelectionParams.transactionType.code
      );
      if (agents.length !== 1) {
        setHasLoadinError(true);
        console.error('Invalid agent count.')
      } else {
        const agent = agents[0];
        setAgent(agent);
        const quotation = await api.getQuotation(
          productSelectionParams.country.id,
          productSelectionParams.currency.id,
          productSelectionParams.sourceCurrency.id,
          productSelectionParams.transactionType.code,
          productSelectionParams.amount,
          productSelectionParams.mode,
          agent.id
        );
        setQuotation(quotation);
        const accountTypes = await api.getAccountTypesForAgent({
          agentId: agent.id,
          currencyId: productSelectionParams.currency.id,
          countryId: productSelectionParams.country.id,
          transactionTypeId: productSelectionParams.transactionType.id
        });
        if (accountTypes.length !== 1) {
          setHasLoadinError(true);
          console.error('Invalid account types count.')
        } else {
          setAccountType(accountTypes[0]);
        }
      }
      setZoneData(await api.getCubaZones());
      setHasLoadinError(false);
    }, (e) => {
      if (e) {
        setHasLoadinError(true);
      }
      setLoading(false);
    });
  }

  React.useEffect(() => {
    loadData();
  }, []);

  React.useEffect(() => {
    setArea(null);
    setMunicipality(null);
  }, [selectedZone]);

  React.useEffect(() => {
    setMunicipality(null);
  }, [selectedArea]);

  const classes = useStyles();

  return (
    <>
      <AlertDialog
        open={hasLoadingError}
        onClose={() => {
          setHasLoadinError(false);
          loadData();
        }}
        title={t('LOADING_ERROR_TITLE')}
        message={t('LOADING_ERROR_DESCRIPTION')}
      />
      <TransferWizard
        step={TransferWizardStep.OPTIONS}
        back={() => history.push('/transfer/destination')}
        canGoNext={!loading && quotation !== undefined}
        next={selectProduct}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              {t('CUBA_HOME_DELIVERY_INTRO')}
            </Typography>
          </Grid>
          {(!loading && zoneData?.zones) &&
            <Grid item xs={12}>
              <Autocomplete
                onChange={(event, value) => {
                  setZone(value);
                }}
                getOptionSelected={(option, value) => option.name === value.name}
                value={selectedZone}
                options={zoneData.zones?.filter(z => {
                  if (productSelectionParams.currency.iso === 'EUR') {
                    return z.name !== 'Provincias'
                  }
                  return true;
                })}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.name}
                renderOption={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors.accountFields?.zone !== undefined}
                    label={t('CHOSE_ZONE')}
                    variant='outlined'
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'chrome-off',
                    }}
                  />
                )}
              />
            </Grid>
          }
          {(!loading && selectedZone) &&
            <Grid item xs={12}>
              <Autocomplete
                onChange={(event, value) => {
                  setArea(value);
                }}
                getOptionSelected={(option, value) => option.name === value.name}
                value={selectedArea}
                options={zoneData!.areas.filter(a => selectedZone.areas.includes(a.name))}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.name}
                renderOption={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors.accountFields?.area !== undefined}
                    label={t('CHOSE_AREA')}
                    variant='outlined'
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'chrome-off',
                    }}
                  />
                )}
              />
            </Grid>
          }
          {(!loading && selectedArea) &&
            <Grid item xs={12}>
              <Autocomplete
                onChange={(event, value) => {
                  setMunicipality(value);
                }}
                getOptionSelected={(option, value) => option.name === value.name}
                value={selectedMunicipality}
                options={selectedArea.municipalities}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.name}
                renderOption={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors.accountFields?.municipality !== undefined}
                    label={t('CHOSE_MUNICIPALITY')}
                    variant='outlined'
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'chrome-off',
                    }}
                  />
                )}
              />
            </Grid>
          }
          {!loading && quotation &&
            <Grid item xs={12}>
              <QuotationInfo quotation={quotation} />
            </Grid>
          }
          {loading &&
            <Grid item xs={12}>
              <Grid container justify='center'>
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>
      </TransferWizard>
    </>
  );
}